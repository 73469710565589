import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PagesService } from 'src/app/services/pages.service';
import { Page } from 'src/app/models/Page';
import ckEditorConfig from 'src/app/ckEditorConfig';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})

export class EditComponent implements OnInit {
  public loading: boolean = false;
  public editMode: {
    active: boolean,
    data: any
  } = {active: false, data: null};
  public submitted: boolean = false;
  public editFormGroup: UntypedFormGroup;

  // tslint:disable:variable-name
  public page_nav_photo: string | File = null;
  public background_image: string | File = null;
  public background_image_right: string | File = null;

  public preselectService;
  public parentMenus = [];
  public Editor = ckEditorConfig.editor;
  public ckConfig = ckEditorConfig.config;

  public currentModule: {
    slug: string,
    name: string
  } = {
    slug: 'website',
    name: 'Site Profil Plus'
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public enumsService: EnumsService,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private pagesService: PagesService,
    public loadingService: LoadingService) {
      this.loading = true;
      this.enumsService.observable.subscribe({
        complete: () => {
          if (this.route.snapshot.params.id) { // EDIT
            this.editMode.active = true;
            this.pagesService.getById(this.route.snapshot.params.id)
              .then((response: Page) => {
                this.editMode.data = response;
                this.editMode.data.page_nav_photo = (response.page_nav_photo) ? [{name: 'affichage de votre fichier', link: response.page_nav_photo}] : null;
                this.editMode.data.background_image = (response.background_image) ? [{name: 'affichage de votre fichier', link: response.background_image}] : null;
                this.editMode.data.background_image_right = (response.background_image_right) ? [{name: 'affichage de votre fichier', link: response.background_image_right}] : null;

                this.loading = false;
                this.editFormGroup = this.formBuilder.group(this.getNormalForm(response));
                if (response.service) {
                  this.preselectService = this.enumsService.enums.services.findIndex(a => a.id === response.service.id);
                }
                this.preSetWebsite()
              })
              .catch((err) => {
                this.loading = false;
                this.alertService.error(err);
              });
          } else {
            this.loading = false;
            this.editFormGroup = this.formBuilder.group(this.getNormalForm());
            this.preSetWebsite()
          }
        }
      });
    }

  get f() { return this.editFormGroup.controls; }

  ngOnInit(): void {}

  getNormalForm(data?: Page): any {
    return {
      parent_menu: [data ? data.parent_menu.id : null, null],
      rank: [data ? data.rank : null, null],
      title: [data ? data.title : '', Validators.required],
      meta_title: [data ? data.meta_title : '', null],
      meta_description: [data ? data.meta_description : '', null],
      slug: [data ? data.slug : '', Validators.required],
      slug_key: [data ? data.slug_key : '', Validators.required],
      content: [data ? data.content : '', null],
      url: [data ? data.url : '', null],
      emails_notifications: [data ? data.emails_notifications : '', null],
      published: [data ? data.published : false, null],
      universe: [data && data.universe ? data.universe.id : 0, null],
      service: [data && data.service ? data.service.id : null, null],
      website: [data ? data.website : 0, null],
      start_at: [data &&  data.start_at ? data.start_at.toString().split('T')[0] : 0, null],
      end_at: [data && data.end_at ? data.end_at.toString().split('T')[0] : 0, null],
      noindex: [data ? data.noindex : false, null],
    }
  }

  preSetWebsite() {
    this.route.data.subscribe((data) => {
      if(data.moduleSlug && data.moduleName) {
        this.currentModule.slug = data.moduleSlug
        this.currentModule.name = data.moduleName
      }

      // Pre set website field
      const website = this.enumsService.enums.websites.find(w => w.slug == this.currentModule.slug)
      if(website) {
        this.editFormGroup.controls.website.patchValue(website.id)
      }

      // Filter for parentMenus select list
      this.parentMenus = this.enumsService.enums.parent_menus.filter(
        parentMenu => parentMenu.module == this.currentModule.slug || parentMenu.id == 0
      )
    })
  }

  onSelectService($event) {
    this.editFormGroup.patchValue({service: $event.id});
  }

  onFilePageNavPhotoSelected(files: Array<File>) {
    this.page_nav_photo = files ? files[0] : '';
    if (!files && this.editMode.data) {
      this.editMode.data.page_nav_photo = null
    }
  }

  onFileBackgroundImageSelected(files: Array<File>) {
    this.background_image = files ? files[0] : '';
    if (!files && this.editMode.data) {
      this.editMode.data.background_image = null
    }
  }

  onFileBackgroundImageRightSelected(files: Array<File>) {
    this.background_image_right = files ? files[0] : '';
    if (!files && this.editMode.data) {
      this.editMode.data.background_image_right = null
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }
    const page = {
      ...this.editFormGroup.value,
      page_nav_photo: this.page_nav_photo,
      background_image: this.background_image,
      background_image_right: this.background_image_right
    };
    if (this.editMode) {
      page.id = this.route.snapshot.params.id;
    }
    this.pagesService.edit(page).then((response: Page) => {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/' + this.currentModule.slug + '/pages/edit', response.id]);
      });

      this.alertService.success((this.editMode && this.editMode.active) ?'Modification enregistrée avec succès.' : 'Page CMS enregistrée avec succès.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

}

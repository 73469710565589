import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { AppSetting } from '../models/AppSetting';

@Injectable({ providedIn: 'root' })
export class AppSettingsService {
  constructor(private apiService: ApiService) {}

  getById(appSettingId) {
    return new Promise((resolve, reject) => {
      this.apiService.get('app_settings/' + appSettingId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('app_settings')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async formatData(appSetting: AppSetting) {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      formData.append('app_setting:key', appSetting.key ? appSetting.key : '');
      formData.append('app_setting:value', appSetting.value ? appSetting.value : '');
      formData.append('app_setting:content', appSetting.content ? appSetting.content : '');
      formData.append('app_setting:process_date_time', appSetting.process_date_time ? appSetting.process_date_time.toString() : '');
      if (appSetting.asset_image !== null) {
        formData.append('app_setting:asset_image', appSetting.asset_image ? appSetting.asset_image : '');
      }

      resolve(formData);
    });
  }

  edit(appSetting) {
    return new Promise((resolve, reject) => {
      this.formatData(appSetting).then((formData) => {
        if (appSetting.id) {
          this.apiService.put('app_settings/' + appSetting.id, formData)
          .then((response: any) => {
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
        } else {
          this.apiService.post('app_settings', formData)
          .then((response: any) => {
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
        }
      });
    });
  }

  delete(appSettingId) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('app_settings/' + appSettingId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  reindex() {
    return new Promise((resolve, reject) => {
      this.apiService.get('app_settings/es_indexing')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
